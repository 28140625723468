<template>
  <div class="navbar-container d-flex content align-items-center">
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder" style="color: #5e5873">
              {{ getUser ? getUser.name : "" }}
              
            </p>
            <p class="user-name">
              {{ getUser ? getUser.role.name : "" }}
            </p>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="getUser ? getUser.profile_image : ''"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          :to="{ name: 'Profile' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logoutButtonClick"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    DarkToggler,
  },
 data() {
    return {
    getLoggedinUser:{},
    // bookingRoles: [
    //   'bk__agm',
    //   'bk__mess_sec',
    //   'bk__fmn_adm'
    // ]
  }
 },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  mounted(){
    this.getLoggedinUser = this.getUser
  },
  methods: {
    ...mapActions({ logout: "appData/logout" }),
    async logoutButtonClick() {
      try {
        const res = await this.logout();
        if (res.status === 204) {
          // if(this.getLoggedinUser.role_data.code_name == 'bu'){
          //   this.$router.push({ name: "BookingLogin" })
          // }
          // if(this.bookingRoles.includes(this.getLoggedinUser.role_data.code_name)){
          if(this.getLoggedinUser.role_data.code_name.includes('bk__')){
            this.$router.push({ name: "BookingLogin" })
          }
          else{
            this.$router.push({ name: "Login" });
          }
        }
      } catch (error) {
        console.log(error);
        // this.displayError(error);
      }
    },
      // async logoutButtonClick() {
      //   try {
      //     const res = await this.logout();
      //     if (res.status === 204) {
      //       console.log("ABCS")
      //       console.log(this.getUser)
      //       if(this.hasRole("bu")){
      //         this.$router.push({ name: "BookingLogin" })
      //       }
      //       else{
      //         this.$router.push({ name: "Login" });
      //       }
      //     }
      //   } catch (error) {
      //     this.displayError(error);
      //   }
      // },

  },
  computed: {
    ...mapGetters({
      hasRole: "appData/hasRole",
      getUser: "appData/getUser",
    }),
  },
};
</script>
